<template>
  <div class="received" style="text-align: left;">
    <van-nav-bar
        title="待收货"
        left-text="返回"
        left-arrow
        @click-left="onReturn"
    />

    <van-list
        v-model="loading"
        v-if="!isDataEmpty"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
    >
      <van-cell v-for="(item,index) in list" :key="index" :title="item.createTime" :icon="icon_todo_date" is-link :to="'orderDetail?orderNo='+item.orderNo">
        <template #label>
          <van-icon :name="icon_huanhuo" v-if="item.orderType == 4 || item.orderType == 5 || item.orderType == 6" />
          <span style="font-size: 18px;color: #565555;">{{item.orderTitle}}</span>
          <van-divider style="margin: 10px 0;" />
          <span style="font-size: 15px;line-height: 30px;">{{item.orderNo}}</span>
          <van-button type="info" size="small" style="float: right;width:100px;" @click="doReceipt(item.orderNo, item.orderType);">确认收货</van-button>
        </template>
      </van-cell>
    </van-list>

    <van-empty description="没有数据哦" v-if="isDataEmpty" />

    <van-dialog class="payTypeDialog" v-model="showDialog4Confirm" title="确认收货" show-cancel-button @confirm="confirmSubmit">
      <van-cell value="支付方式：" />
      <van-radio-group v-model="payType">
        <van-cell-group>
          <van-cell title="现金" clickable @click="payType = '1'">
            <template #right-icon>
              <van-radio name="1" />
            </template>
          </van-cell>
          <van-cell title="扫码" clickable @click="payType = '2'">
            <template #right-icon>
              <van-radio name="2" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </van-dialog>
  </div>
</template>

<script>
import icon_todo_date from "@/assets/icon_todo_date.png";
import icon_huanhuo  from "@/assets/icon_huanhuo.png";
import { getOrderList, receipt } from "@/api/order";
import { Toast } from "vant";

export default {
  name: "Received",
  components: {},
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      icon_todo_date: icon_todo_date,
      icon_huanhuo: icon_huanhuo,
      pageNo: 0,
      pageSize: 10,
      isDataEmpty: false,
      showDialog4Confirm: false,
      confirmOrderNo: null,
      payType: '1',
    };
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
    },
    onLoad() {
      this.pageNo++;
      getOrderList(3, this.pageNo, this.pageSize).then(response => {
        console.log(response.data);
        let data = response.data;
        // 加载状态结束
        this.loading = false;
        // 没有数据
        if(data.total == 0) {
          this.isDataEmpty = true;
          this.finished = true;
        } else {
          for(let i in data.records) {
            let item = data.records[i];
            console.log(item);
            this.list.push({orderNo:item.orderNo, createTime: item.createTime.replace('T',' '), orderTitle: item.orderTitle, orderType: item.orderType});
          }
          if(data.current == data.pages) {
            // 数据全部加载完成
            this.finished = true;
          }
        }
      });
    },
    doReceipt(orderNo, orderType) {
      event.stopPropagation();
      console.log(orderNo, orderType);
      // 换货的时候 不需要选择支付方式
      if(orderType == 4 || orderType == 5 || orderType == 6) {
        this.confirmOrderNo = orderNo;
        this.payType = '3'; // 换货
        this.confirmSubmit();
      } else {
        this.payType = '1'; // 默认现金
        this.confirmOrderNo = orderNo;
        this.showDialog4Confirm = true;
      }
    },
    confirmSubmit() {
      console.log(this.payType);
      receipt(this.confirmOrderNo, this.payType).then(r => {
        console.log(r);
        Toast.success('确认收货成功');
        this.list = [];
        this.pageNo = 0;
        this.isDataEmpty = false;
        this.loading = false;
        this.finished = false;
      });
    },
    onReturn() {
      this.$router.go(-1);
    }
  }
};
</script>
<style lang="scss" scoped>
.received {
  background: #f5f5f5;
}
.van-cell__title > span {
 color:#a5a0a0;
}
.payTypeDialog .van-cell__title > span {
  color: #463434;
}
.van-cell {
  font-size: 16px;
  margin-bottom: 10px;
}
.van-icon__image {
  width: 1.2em;
  height: 1.2em;
}
.van-button--small {
  font-size: 14px;
}
</style>
